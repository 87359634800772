import React from 'react'
import { CFView, DefaultScreen, MobileScreen } from 'components'
import OrderPickupButton from './OrderPickupButton'
import { hero, mobileHero } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          column
          center
          h="90VH"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <CFView column justifyBetween alignCenter pb="30px" ph="10px">
            <CFView pt="15px">
              <CFView
                column
                center
                bg="rgba(0,0,0,0.6)"
                p="10px 10px"
                br="30px"
              >
                <CFView bold h1 textCenter color="white">
                  Order Online and Redeem Rewards!
                </CFView>
              </CFView>
            </CFView>
            <CFView pt="20px">
              <OrderPickupButton />
              {/* <OrderDeliveryButton /> */}
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          column
          justifyEnd
          h="90VH"
          maxHeight="700px"
          w="100%"
          maxWidth="1400px"
          m="0 auto"
          image={`url(${hero}) center / cover no-repeat`}
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
        >
          <CFView row justifyAround alignCenter pb="30px" ph="30px">
            <CFView
              column
              center
              bg="rgba(0,0,0,0.6)"
              ph="30px"
              pv="5px"
              br="30px"
            >
              <CFView bold h1 textCenter color="white">
                Order Online and Redeem Rewards!
              </CFView>
            </CFView>
            <CFView row justifyBetween pulsate>
              <OrderPickupButton />
              {/* <OrderDeliveryButton /> */}
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
