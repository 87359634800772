import React from 'react'
import { CFSelect, CFView, LocationSelect, MobileScreen } from 'components'
import Header from './Header'
import Hero from './Hero'
import Promotions from './Promotions'
import About from './About'
import Gallery from './Gallery'
import Contact from './Contact'
import Footer from './Footer'
import WelcomeModal from './WelcomeModal'
import { homebg } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFView image={`url(${homebg}) center / cover no-repeat`}>
      <Header />
      <Hero />
      <Promotions />
      <About />
      <Gallery />
      <Contact />
      <Footer />
      <CFSelect selector={dispatch.restaurant.getWelcomeMessage}>
        <WelcomeModal />
      </CFSelect>
    </CFView>
  )
}
