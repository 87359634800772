import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFView,
  CFImage,
  CFSelect,
  CFLink,
  LocationSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  contactbg,
  logo,
  logoTitle,
  viewMenu,
  menu1,
  menu2,
  menu3,
  menu4,
  menu5,
  menu6,
  menu7,
  menu8,
  menu9,
  menu10,
  menu11,
  menu12,
  menu,
} from 'images'
import { dispatch } from 'store'
import ViewMenu from './ViewMenu'

const images = [
  {
    src: menu1,
  },
  {
    src: menu2,
  },
  {
    src: menu3,
  },
  {
    src: menu4,
  },
  {
    src: menu5,
  },
  {
    src: menu6,
  },
  {
    src: menu7,
  },
  {
    src: menu8,
  },
  {
    src: menu9,
  },
  {
    src: menu10,
  },
  {
    src: menu11,
  },
  {
    src: menu12,
  },
]

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView
          column
          center
          image={`url(${contactbg}) center / cover no-repeat`}
        >
          <CFView row center alignCenter>
            <CFImage h="50px" mt="10px" src={logo} alt="Sushi Kaido Logo" />
            <CFImage
              h="35px"
              ml="15px"
              src={logoTitle}
              alt="Sushi Kaido Logo Title"
            />
          </CFView>
          <LocationSelect />
          <CFLink mt="4px" href={menu} hover target="_blank">
            <CFImage
              h="65px"
              src={viewMenu}
              alt="Sushi Kaido View Menu Button"
            />
          </CFLink>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          center
          maxWidth="1400px"
          m="0 auto"
          h="70px"
          image={`url(${contactbg}) center / cover no-repeat`}
          boxShadow="2px 2px 2px rgba(0,0,0,.5)"
        >
          <CFView w="100%" maxWidth="1400px" relative row justifyEnd>
            <CFView row justifyStart absolute left="35px" zIndex={1}>
              <CFImage h="140px" mt="5px" src={logo} alt="Sushi Kaido Logo" />
              <CFImage
                h="50px"
                mt="7px"
                src={logoTitle}
                alt="Sushi Kaido Logo Title"
              />
              <CFSelect selector={dispatch.restaurant.getHasMultipleLocations}>
                <CFView mt="15px" zIndex={99} ml="15px">
                  <LocationSelect />
                </CFView>
              </CFSelect>
            </CFView>
            {/* <CFLink mt="8px" mr="25px" href={menu} hover target="_blank">
              <CFImage
                h="60px"
                src={viewMenu}
                alt="Sushi Kaido View Menu Button"
              />
            </CFLink> */}
            <CFView mt="10px">
              <ViewMenu />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
