import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import {
  CFImage,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import LocationButton from './LocationButton'
import { logo, contactbg } from 'images'
import { dispatch } from 'store'

export default ({ onRequestClose }) => {
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const changeLocation = (locationId) => {
    dispatch.restaurant.setLocationId(locationId)
    if (isMobile) {
      history.push('/')
    } else {
      onRequestClose()
    }
  }

  return (
    <>
      <MobileScreen>
        <CFSelect selector={dispatch.restaurant.getLocations}>
          {(locations) => {
            return (
              <CFView
                h="100%"
                w="100%"
                column
                alignCenter
                overflowY="auto"
                pv="30px"
                style={{ '-webkit-overflow-scrolling': 'touch' }}
                image={`url(${contactbg}) center / cover no-repeat`}
              >
                <CFImage h="120px" src={logo} alt="Logo" />
                <CFView h3 bold white mv="20px">
                  Please choose a location:
                </CFView>
                <CFView w="90%">
                  {Object.values(locations)
                    .filter((location) => !location.inactive)
                    .sort((a, b) => {
                      if (a.locationName < b.locationName) {
                        return -1
                      }
                      if (a.locationName > b.locationName) {
                        return 1
                      }
                      return 0
                    })
                    .map((location) => (
                      <LocationButton
                        key={location.id}
                        name={location.locationName}
                        address={location.address}
                        onClick={() => changeLocation(location.id)}
                      />
                    ))}
                </CFView>
              </CFView>
            )
          }}
        </CFSelect>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="480px" pt="20px" pb="20px" ph="20px" column>
          <CFSelect selector={dispatch.restaurant.getLocations}>
            {(locations) => {
              return (
                <CFView fill column center>
                  <CFImage h="100px" src={logo} alt="Logo" />
                  <CFView h4 bold mv="20px">
                    Please choose a location:
                  </CFView>
                  {Object.values(locations)
                    .filter((location) => !location.inactive)
                    .sort((a, b) => {
                      if (a.locationName < b.locationName) {
                        return -1
                      }
                      if (a.locationName > b.locationName) {
                        return 1
                      }
                      return 0
                    })
                    .map((location) => (
                      <LocationButton
                        key={location.id}
                        name={location.locationName}
                        address={location.address}
                        onClick={() => changeLocation(location.id)}
                      />
                    ))}
                </CFView>
              )
            }}
          </CFSelect>
        </CFView>
      </DefaultScreen>
    </>
  )
}
