import React from 'react'
import {
  CFSelect,
  CFView,
  CFLink,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { orderPickupButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {(locationId) => {
        let orderingUrl =
          process.env.REACT_APP_ENVIRONMENT === 'dev'
            ? `https://demo.codefusion.tech/restaurants/ebisu/locations/${locationId}`
            : 'https://order.codefusion.tech/restaurants/Fspn20L2gmN2s4CL6RIy/locations/M2FOvPPmKri7OcATwqBN'
        return (
          <CFView>
            <MobileScreen>
              <CFLink href={orderingUrl}>
                <CFView hover>
                  <CFImage
                    src={orderPickupButton}
                    maxWidth="300px"
                    alt="Pickup"
                  />
                </CFView>
              </CFLink>
            </MobileScreen>
            <DefaultScreen>
              <a href={orderingUrl}>
                <CFView hover>
                  <CFImage
                    src={orderPickupButton}
                    maxWidth="300px"
                    alt="Pickup"
                  />
                </CFView>
              </a>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
