import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h2 futura bold color="rgb(149, 123, 96)" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            <CFLink href="https://www.facebook.com/pages/category/Japanese-Restaurant/Sushi-Kaido-107247894227145/">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Sushi Kaido Facebook"
              />
            </CFLink>
            {/* <CFLink href="https://goo.gl/maps/xiCDRXNWTCEZRNQG6">
              <CFImage w="45px" pr="10px" src={yelp} alt="Sushi Kaido Yelp" />
            </CFLink>
            <CFLink href="https://goo.gl/maps/xiCDRXNWTCEZRNQG6">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="Sushi Kaido Zomato"
              />
            </CFLink>
            COMING SOON... */}
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="20px" column justifyStart>
          <CFView h2 futura bold color="rgb(149, 123, 96)" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            <CFLink href="https://www.facebook.com/pages/category/Japanese-Restaurant/Sushi-Kaido-107247894227145/">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Sushi Kaido Facebook"
              />
            </CFLink>
            {/* <CFLink href="https://goo.gl/maps/xiCDRXNWTCEZRNQG6">
              <CFImage w="45px" pr="10px" src={yelp} alt="Sushi Kaido Yelp" />
            </CFLink>
            <CFLink href="https://goo.gl/maps/xiCDRXNWTCEZRNQG6">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="Sushi Kaido Zomato"
              />
            </CFLink>
            COMING SOON... */}
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
