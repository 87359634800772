import React from 'react'
import { CFView, DefaultScreen, MobileScreen } from 'components'
import { contactbg } from 'images'
import RestaurantDetails from './RestaurantDetails'
import Hours from './Hours'
import Map from './Map'
import SocialMedia from './SocialMedia'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          column
          center
          image={`url(${contactbg}) center / cover no-repeat`}
          pt="20px"
        >
          <Map />
          <CFView pv="20px" w="100%" column center>
            <RestaurantDetails />
            <SocialMedia />
            <Hours />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          column
          center
          image={`url(${contactbg}) center / cover no-repeat`}
          pt="20px"
        >
          <Map />
          <CFView w="100%" pv="30px" row alignCenter justifyAround>
            <CFView w="100%" row justifyAround maxWidth="1000px">
              <CFView column>
                <RestaurantDetails />
                <SocialMedia />
              </CFView>
              <Hours />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
